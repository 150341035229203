const routes = [
  {
    viewPath: 'components/views/Welcome',
    router: 'public',
    urlPath: '/welcome',
  },
  {
    viewPath: 'components/views/Signup',
    router: 'public',
    urlPath: '/signup',
  },
  {
    viewPath: 'components/views/Login',
    router: 'public',
    urlPath: '/login',
  },
  {
    viewPath: 'components/views/InvestorDashboard',
    router: 'private',
    type: 'investor',
    layout: 'components/global/Layouts/App.layout',
    urlPath: '/investor/dashboard',
  },
  {
    viewPath: 'components/views/InvestorSettings',
    router: 'private',
    type: 'investor',
    layout: 'components/global/Layouts/App.layout',
    urlPath: '/investor/settings',
  },
  {
    viewPath: 'components/views/InvestorPortfolio',
    router: 'private',
    type: 'investor',
    layout: 'components/global/Layouts/App.layout',
    urlPath: '/investor/portfolio',
  },
  {
    viewPath: 'components/views/OnboardingInvestorInfo',
    router: 'private',
    type: 'investor',
    layout: 'components/global/Layouts/Onboarding.layout',
    urlPath: '/on-boarding/investor-info',
  },
  {
    viewPath: 'components/views/FounderDashboard',
    router: 'private',
    type: 'founder',
    layout: 'components/global/Layouts/App.layout',
    urlPath: '/founder/dashboard',
  },
  {
    viewPath: 'components/views/FounderSettings',
    router: 'private',
    type: 'founder',
    layout: 'components/global/Layouts/App.layout',
    urlPath: '/founder/settings',
  },
  {
    viewPath: 'components/views/FounderPipeline',
    router: 'private',
    type: 'founder',
    layout: 'components/global/Layouts/App.layout',
    urlPath: '/founder/pipeline',
  },
  {
    viewPath: 'components/views/PublicProfileFounder',
    router: 'private',
    type: 'any',
    urlPath: '/public/founder/:id',
  },
  {
    viewPath: 'components/views/PublicProfileInvestor',
    router: 'private',
    type: 'any',
    urlPath: '/public/investor/:id',
  },
  {
    viewPath: 'components/views/FounderDealShared',
    router: 'public',
    urlPath: '/deal/:id',
  },
  {
    viewPath: 'components/views/PublicProfileFund',
    router: 'private',
    type: 'any',
    layout: 'components/global/Layouts/App.layout',
    urlPath: '/fund/profile/:id',
  },
  {
    viewPath: 'components/views/InvestorEditProfile',
    router: 'private',
    type: 'investor',
    layout: 'components/global/Layouts/App.layout',
    urlPath: '/investor/edit-profile/:id',
  },
  {
    viewPath: 'components/views/FounderEditProfile',
    router: 'private',
    type: 'founder',
    layout: 'components/global/Layouts/App.layout',
    urlPath: '/founder/edit-profile/:id',
  },
  {
    viewPath: 'components/views/InvestorPipeline',
    router: 'private',
    type: 'investor',
    layout: 'components/global/Layouts/App.layout',
    urlPath: '/investor/pipeline',
  },
  {
    viewPath: 'components/views/InvestorPipelineDetail',
    router: 'private',
    type: 'investor',
    layout: 'components/global/Layouts/App.layout',
    urlPath: '/investor/pipeline-detail/:id',
  },
  {
    viewPath: 'components/views/FounderPipelineDetail',
    router: 'private',
    type: 'founder',
    layout: 'components/global/Layouts/App.layout',
    urlPath: '/founder/pipeline-detail/:investorId/:dealId?',
  },
  {
    viewPath: 'components/views/Deals',
    router: 'private',
    type: 'founder',
    layout: 'components/global/Layouts/App.layout',
    urlPath: '/founder/deals',
  },
  {
    viewPath: 'components/views/EditDeal',
    router: 'private',
    type: 'founder',
    layout: 'components/global/Layouts/App.layout',
    urlPath: '/founder/deal/:id',
  },
  {
    viewPath: 'components/views/FounderSharedCompanyProfile',
    router: 'private',
    type: 'any',
    urlPath: '/public/company/:id',
  },
  {
    viewPath: 'components/views/InvestorPortfolioDetail',
    router: 'private',
    type: 'investor',
    layout: 'components/global/Layouts/App.layout',
    urlPath: '/investor/portfolio-detail/:id',
  },
  {
    viewPath: 'components/views/OnboardingSelectUserType',
    router: 'private',
    type: 'any',
    layout: 'components/global/Layouts/Onboarding.layout',
    urlPath: '/on-boarding/select-user-type',
  },
  {
    viewPath: 'components/views/OnboardingCompanyName',
    router: 'private',
    type: 'any',
    layout: 'components/global/Layouts/Onboarding.layout',
    urlPath: '/on-boarding/company-name',
  },
  {
    viewPath: 'components/views/OnboardingContactInfo',
    router: 'private',
    type: 'any',
    layout: 'components/global/Layouts/Onboarding.layout',
    urlPath: '/on-boarding/contact-info',
  },
  {
    viewPath: 'components/views/OnboardingBasicInfo',
    router: 'private',
    type: 'any',
    layout: 'components/global/Layouts/Onboarding.layout',
    urlPath: '/on-boarding/basic-info',
  },
  {
    viewPath: 'components/views/OnboardingFounderInfo',
    router: 'private',
    type: 'founder',
    layout: 'components/global/Layouts/Onboarding.layout',
    urlPath: '/on-boarding/founder-info',
  },
  {
    viewPath: 'components/views/Signout',
    router: 'private',
    type: 'any',
    urlPath: '/signout',
  },
  {
    viewPath: 'components/views/FounderDealCreate',
    router: 'private',
    type: 'founder',
    urlPath: '/founder/create/deal',
  },
  {
    viewPath: 'components/views/VerifyInvitation',
    router: 'public',
    layout: 'components/global/Layouts/Verify.layout',
    urlPath: '/invite/:id',
  },
  {
    viewPath: 'components/views/InvestorDealCreate',
    router: 'private',
    type: 'investor',
    urlPath: '/investor/create/deal',
  },
  {
    viewPath: 'components/views/FounderInvestorCreate',
    router: 'private',
    type: 'founder',
    urlPath: '/founder/create/investor',
  },
  {
    viewPath: 'components/views/VerifyEmail',
    router: 'public',
    layout: 'components/global/Layouts/Verify.layout',
    urlPath: '/verify/:id',
  },
  {
    viewPath: 'components/views/JoinRequests',
    router: 'public',
    urlPath: '/join-requests',
  },
  {
    viewPath: 'components/views/VerifyDealShare',
    type: 'any',
    router: 'public',
    layout: 'components/global/Layouts/Verify.layout',
    urlPath: '/verify-deal-share/:id',
  },
  {
    viewPath: 'components/views/Subscription',
    type: 'any',
    router: 'private',
    urlPath: '/subscription',
  },
  {
    viewPath: 'components/views/InvestorPortfolioCreate',
    type: 'any',
    router: 'investor',
    urlPath: '/investor/create/portfolio',
  },
  {
    viewPath: 'components/views/OnboardingDemographics',
    type: 'any',
    router: 'private',
    layout: 'components/global/Layouts/Onboarding.layout',
    urlPath: '/on-boarding/demographics',
  },
  {
    viewPath: 'components/views/VerifyOrgInvite',
    router: 'public',
    layout: 'components/global/Layouts/Verify.layout',
    urlPath: '/verify-org-invite/:id',
  },
];

const founder = routes.filter(route => (['any', 'founder'].includes(route?.type) || route.router === 'public'));
const investor = routes.filter(route => (['any', 'investor'].includes(route?.type) || route.router === 'public'));
const developer = routes.filter(route => (['any', 'investor', 'founder'].includes(route?.type) || route.router === 'public'));
const publicRoutes = routes.filter(route => (route.router === 'public'));

export default {
  routes,
  founder,
  investor,
  dev: developer,
  public: publicRoutes,
};
