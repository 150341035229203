import loader from 'images/loader.svg';

const Loader = ({
  message = '',
  width = '50',
  className,
}) => (
  <div className={className}>
    <img src={loader} width={width} alt="" />
    {message && (
      <h4 className="mt-4">{message}</h4>
    )}
  </div>
);

export default Loader;
