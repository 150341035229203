/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */

import { Route, Redirect } from 'react-router-dom';
import $user from 'signals/User.signal';
import { REDIRECT_PATHS } from '../../../../constants'; // Absolute import Conflicts with Node Module

const PrivateRoute = ({ component: Component, type, layout: Layout, ...rest }) => {
  const { isSignedIn, userType } = $user.value;

  const redirect = () => {
    if (isSignedIn) {
      return REDIRECT_PATHS[userType];
    }

    return '/';
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        (
          isSignedIn ? (
            Layout ? (
              <Layout><Component {...props} /></Layout>
            ) : (
              <Component {...props} />
            )
          ) : (
            <Redirect to={redirect()} />
          )
        )
      )}
    />
  );
};

export default PrivateRoute;
