import { getConfig } from 'config/config';
import { ENVS_WITH_LOG_ENABLE } from '../constants';

const env = getConfig('ENV');

const exception = ({
  message = 'Something went wrong',
  type = 'notification',
  status,
  label = 'Error',
  info,
}) => {
  const returnErrorObject = {
    message,
    type,
  };

  if (status) returnErrorObject.status = status;
  if (info) returnErrorObject.info = info;

  if (ENVS_WITH_LOG_ENABLE.includes(env)) {
    console.info(`${label} `, returnErrorObject);
  }

  return returnErrorObject;
};

export default exception;
