import Signal from 'libs/signals';

const $user = Signal({
  isLoading: true,
  isSignedIn: false,
  userType: null,
  mode: 'dark-mode',
});

export default $user;
