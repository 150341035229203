import displayError from 'utils/displayError';
import { signOut } from 'utils/auth';
import $user from 'signals/User.signal';
import Fetcher from 'libs/fetcher';
import Signal from 'libs/signals';
import { $auth } from 'signals/global.signals';
import displayNotification from 'utils/displayNotification';

const fetcher = new Fetcher();

export const $verifyEmail = Signal({
  isIdInvalid: false,
});

export const handleVerification = async (id, history) => {
  $verifyEmail.loadingStart();

  try {
    if ($user.value?.id) {
      await signOut();
    }

    const validId = await fetcher.get({
      path: `/verificationlinks/verify/${id}/public`,
    });

    if (validId) {
      const { email, user_id: userId, domain, type, company_email: companyEmail } = validId;

      await fetcher.put({
        path: '/users/verify/public',
        body: {
          id: userId,
        },
      });

      $auth.update({ userType: type === 'Founder' ? 'founder' : 'investor' });
      $verifyEmail.update({
        verification_id: id,
        userId,
        email,
        companyEmail,
        domain,
        type,
      });

      displayNotification({ title: 'Success!', message: 'Your email has been verified. Please log in again to continue.' });
      history.push('/login');
    } else {
      $verifyEmail.update({ isIdInvalid: true });
    }
  } catch (e) {
    displayError(e);
  } finally {
    $verifyEmail.loadingEnd();
  }
};

export default handleVerification;
