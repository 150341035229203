import Signal from 'libs/signals';

export const $view = Signal({});
export const $form = Signal({});
export const $auth = Signal({});
export const $list = Signal({});
export const $detail = Signal({});

export const handleFormChange = ({ name, value }) => {
  $form.update({
    [name]: value,
  });
};
