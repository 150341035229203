export const METHODS_WITH_BODY = ['PUT', 'POST', 'PATCH', 'DELETE'];
export const ENVS_WITH_LOG_ENABLE = ['development'];
// eslint-disable-next-line
export const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const REDIRECT_PATHS = {
  investor: '/investor/dashboard',
  founder: '/founder/dashboard',
  dev: '/founder/dashboard',
};

export const mimeToFileTypeMapping = {
  'application/pdf': 'pdf',
  'text/csv': 'csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
};

export const REDIRECT_FROM = ['/', '/on-boarding/founder-info', '/on-boarding/investor-info', '/on-boarding/demographics'];

export const ROLES = {
  1: 'Founder',
  2: 'Co-founder',
  3: 'CEO',
  4: 'CTO',
  5: 'CFO',
  6: 'Managing Partner',
  7: 'General Partner',
  8: 'Partner',
  9: 'Associate',
  10: 'Analyst',
  11: 'Angel',
  12: 'Accounting',
  13: 'Diligence',
};
