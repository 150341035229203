import 'firebase/auth';
import firebase from 'firebase/app';
import { auth } from 'utils/firebase';

export const signIn = async (email, password) => {
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  const result = await auth.signInWithEmailAndPassword(email, password);
  return result;
};

export const signInWithGoogle = async () => {
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  await auth.signInWithPopup(googleProvider);
};

export const signOut = async () => {
  await auth.signOut();
};

export const createNewUser = async (email, password) => {
  const user = await auth.createUserWithEmailAndPassword(email, password);
  return user;
};

export const sendPasswordResetEmail = async (email) => {
  await auth.sendPasswordResetEmail(email);
};

export const getFirebaseToken = async () => {
  if (firebase.auth().currentUser) {
    await firebase.auth().currentUser.getIdToken();
  }
};

export const currentUser = async () => {
  await firebase.auth().currentUser.currentUser();
};

export const fetchUserInfo = async (userId) => {
  try {
    const userRef = firebase.firestore().collection('users').doc(userId);

    return userRef.get().then((doc) => {
      if (doc.exists) {
        return doc.data();
      }
      return {};
    }).catch((error) => error);
  } catch (error) {
    return error;
  }
};
