import notifierSignal from 'signals/notifier.signal';

const displayNotification = ({
  message,
  title,
  timeOut = 3000,
}) => notifierSignal.update({
  message,
  title,
  timeOut,
});

export default displayNotification;
