import $user from 'signals/User.signal';
import ModalSignal from 'signals/Modal.signal';
import AppModal from 'components/global/AppModal';
import { getConfig } from 'config/config';

const env = getConfig('ENV');

const SubscribeModal = () => {
  const subscribeOptions = {
    investor: <stripe-pricing-table
      pricing-table-id={env === 'production' ? 'prctbl_1Ok5vND4vB6y4ECDD5EQnuTJ' : 'prctbl_1OK6AGD4vB6y4ECDk7St7Qvv'}
      publishable-key={env === 'production' ?
        'pk_live_51NQZIVD4vB6y4ECDywEGGKxz1MCxW1JVKlN6KV0PN1jIdQTwA1ZcvFuS3R9ZrND2zMX57AUqYVqBYQEsmeUTM2ze00Jkozy52r' :
        'pk_test_51NQZIVD4vB6y4ECDFshP7pkfnqsaMeW1Al7FVQAQUtccsBqtAdFA7N7Wx389qj0wGGn8eY4eRIRD81oCNJ9UuPj4000rPVnNdn'}
      client-reference-id={$user?.value?.organization?.id ?? '116'}
      customer-email={$user?.value?.organization?.billing_email ?? $user?.value?.organization?.email}
    />,
    founder: <stripe-pricing-table
      pricing-table-id={env === 'production' ? 'prctbl_1Ok62BD4vB6y4ECDyq0fKorN' : 'prctbl_1Of7g9D4vB6y4ECDCrCuCfwP'}
      publishable-key={env === 'production' ?
        'pk_live_51NQZIVD4vB6y4ECDywEGGKxz1MCxW1JVKlN6KV0PN1jIdQTwA1ZcvFuS3R9ZrND2zMX57AUqYVqBYQEsmeUTM2ze00Jkozy52r' :
        'pk_test_51NQZIVD4vB6y4ECDFshP7pkfnqsaMeW1Al7FVQAQUtccsBqtAdFA7N7Wx389qj0wGGn8eY4eRIRD81oCNJ9UuPj4000rPVnNdn'}
      client-reference-id={$user?.value?.organization?.id ?? '116'}
      customer-email={$user?.value?.organization?.billing_email ?? $user?.value?.organization?.email}
    />,
    angel: <stripe-pricing-table
      pricing-table-id={env === 'production' ? 'prctbl_1Ok5ywD4vB6y4ECDHtRacCit' : 'prctbl_1Of7kPD4vB6y4ECDxFCON3SP'}
      publishable-key={env === 'production' ?
        'pk_live_51NQZIVD4vB6y4ECDywEGGKxz1MCxW1JVKlN6KV0PN1jIdQTwA1ZcvFuS3R9ZrND2zMX57AUqYVqBYQEsmeUTM2ze00Jkozy52r' :
        'pk_test_51NQZIVD4vB6y4ECDFshP7pkfnqsaMeW1Al7FVQAQUtccsBqtAdFA7N7Wx389qj0wGGn8eY4eRIRD81oCNJ9UuPj4000rPVnNdn'}
      client-reference-id={$user?.value?.organization?.id ?? '116'}
      customer-email={$user?.value?.organization?.billing_email ?? $user?.value?.organization?.email}
    />,
  };

  if (ModalSignal.value === false) {
    return null;
  }

  return (
    <AppModal
      headerText={<h4 className="text-center pt-24">Select a Plan:</h4>}
      show={ModalSignal.value}
      handleClose={() => ModalSignal.update(false)}
      bodyText={$user.value?.userOrg?.investor_org?.investor_type === 'Angel' ? subscribeOptions.angel : subscribeOptions[$user?.value?.userType]}
    />
  );
};

export default SubscribeModal;
