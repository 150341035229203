import Exception from 'utils/exception';

export const handleHttpError = (httpResponse) => {
  if (!httpResponse.error) return;

  throw Exception({
    label: 'Fetcher Error',
    message: httpResponse?.error?.message,
    status: httpResponse?.error?.status,
    type: httpResponse?.error?.type,
    info: httpResponse?.reqBody,
  });
};

export default {
  handleHttpError,
};
