import { getConfig } from 'config/config';
import notifierSignal from 'signals/notifier.signal';

const env = getConfig('ENV');

const displayError = ({
  message,
  title,
  timeOut = env === 'production' ? 7000 : 15000,
}) => {
  notifierSignal.update({
    message,
    title,
    timeOut,
  });
};

export default displayError;
