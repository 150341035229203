/* eslint-disable prefer-rest-params */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
/* eslint-disable global-require */
import React, { Suspense, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import './scss/style.scss';
import Loader from 'components/global/Loader';
import PublicRoute from 'components/global/Routes/PublicRoute';
import PrivateRoute from 'components/global/Routes/PrivateRoute';
import $user from 'signals/User.signal';
import SubscribeModal from 'components/global/SubscribeModal/SubscribeModal';
import Notifier from 'components/global/Notifier/Notifier';
import initApp from 'utils/initApp';
import routes from 'config/routes';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { REDIRECT_PATHS, REDIRECT_FROM } from './constants';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
);

initApp.registerFirebaseAuthListener();
initApp.registerUserback();

const LoadingSuspense = () => (
  <div className="min-vh-100 w-100 bg-base d-flex justify-content-center align-items-center flex-grow-1">
    <Loader message="" className="text-center" />
  </div>
);

const UserRoutes = ({ routes: appRoutes }) => (
  <Switch>
    <Suspense fallback={<LoadingSuspense />}>
      {appRoutes.map((route) => {
        let routeFile;
        try {
          routeFile = import(`./${route.viewPath}`);
        } catch (error) {
          console.error('ERROR', error);
          return null;
        }

        const component = React.lazy(() => routeFile);
        const layout = route.layout ? React.lazy(() => import(`./${route.layout}`)) : null;

        if (route.router === 'private') {
          return <PrivateRoute path={route.urlPath} component={component} key={route.viewPath} type={route.type} layout={layout} />;
        }

        return <PublicRoute path={route.urlPath} component={component} key={route.viewPath} layout={layout} />;
      })}
    </Suspense>
  </Switch>
);

const App = () => {
  const {
    isLoading: loading,
    onboarding_step: onboardingStep,
    isSignedIn,
    userType,
    accountStatus,
  } = $user.value;
  const ROUTES = routes[userType] || routes.routes.filter(rte => rte.urlPath === '/on-boarding/select-user-type');
  const inactiveRoutes = [...routes.public, ...routes.routes.filter(rte => ['/signout', '/subscription'].includes(rte.urlPath))];
  const publicPaths = routes.public.map(route => route.urlPath.split(':')[0]);
  const userPaths = ROUTES?.map(rte => rte.urlPath.split(':')[0]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      (function (c, l, a, r, i, t, y) {
        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments); };
        t = l.createElement(r);
        t.async = 1;
        t.src = `https://www.clarity.ms/tag/${i}`;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      }(window, document, 'clarity', 'script', 'o48hjdc80t'));
    }
  }, []);

  useEffect(() => {
    const isVerifyRoute = window.location.pathname.includes('/verify');
    const isOnboardingRoute = window.location.pathname.includes('/on-boarding');
    if (isSignedIn && onboardingStep !== 'completed' && !isVerifyRoute && !isOnboardingRoute) {
      window.location.replace(onboardingStep);
    }
  }, [isSignedIn, onboardingStep]);

  if (loading) {
    return (
      <div className="min-vh-100 w-100 d-flex bg-base justify-content-center align-items-center flex-grow-1">
        <Loader message="" className="text-center" />
      </div>
    );
  }

  if (isSignedIn === false) {
    return (
      <>
        <Notifier />
        <Router>
          <Route component={() => <UserRoutes routes={routes.public} />} />
          {(!publicPaths.find(path => window.location.pathname.includes(path))) && <Redirect to="/welcome" />}
        </Router>
      </>
    );
  }

  if (onboardingStep === 'completed' && !['active', 'basic'].includes(accountStatus)) {
    return (
      <>
        <Notifier />
        <SubscribeModal />
        <Router>
          <Route component={() => <UserRoutes routes={inactiveRoutes} />} />
          <Redirect to="/subscription" />
        </Router>
      </>
    );
  }

  return (
    <>
      <Notifier />
      <SubscribeModal />
      <Router>
        <Switch>
          <Route component={() => <UserRoutes routes={ROUTES} />} />
        </Switch>
        {(REDIRECT_FROM.includes(window.location.pathname)
          && onboardingStep === 'completed') && (<Redirect to={REDIRECT_PATHS[userType]} />)}

        {(!window.location.pathname.includes('/verify')
          && onboardingStep !== 'completed') && (<Redirect to={onboardingStep} />)}

        {!userPaths?.find(path => window.location.pathname.includes(path)) && (
          <Redirect to={`/${userType}/dashboard`} />
        )}
      </Router>
    </>
  );
};

export default App;
