/* eslint-disable react/jsx-props-no-spreading */
import { Route, Redirect } from 'react-router-dom';
import $user from 'signals/User.signal';

const PublicRoute = ({ component: Component, restricted, layout: Layout, ...rest }) => {
  const isSignedIn = $user.valueOf('isSignedIn');

  return (
    <Route
      {...rest}
      render={(props) => (
        isSignedIn && restricted
          ? <Redirect to="/" />
          : (
            <>
              {Layout
                ? (
                  <Layout>
                    <Component {...props} />
                  </Layout>
                ) : (<Component {...props} />)}
            </>
          )
      )}
    />
  );
};
export default PublicRoute;
