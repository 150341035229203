const init = () => {
  const d = document;
  window.Userback = window.Userback || {};
  window.Userback.access_token = '34769|83760|ksUnFdmWEsWU3CvfGKILuh0kK';
  const s = d.createElement('script'); s.async = true;
  s.src = 'https://static.userback.io/widget/v1.js';
  (d.head || d.body).appendChild(s);
};

export default init;
