import React from 'react';
import ReactDOM from 'react-dom';
import 'index.css';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import App from 'App';
import $user from 'signals/User.signal';

ReactDOM.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <div className={$user.value?.mode ?? 'dark-mode'}>
        <App />
      </div>
    </DndProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
